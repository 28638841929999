.feature-row {
  /*margin: 100px 0;*/
}

.feature-list {
  grid-template-columns: repeat(var(--grid-columns-team), 1fr);
  display: grid;
  column-gap: 50px;
  row-gap: 50px;
  margin-top: 50px;
}

.feature-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.feature-icon-container {
  background-color: var(--main-blue);
  border-radius: 100%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-icon {
  font-size: 40px;
}

.feature-icon > path {
  fill: black;
}

.feature-title {
  font-size: 24px;
  margin: 20px 0;
  font-weight: 500;
}

.feature-description {
  color: var(--text-color);
  font-size: var(--base-text-size);
  margin: 0;
  text-align: center;
  width: 190px;
}


@media screen and (max-width: 600px) {

  .feature-row {
    width: 100%;
  }

  .feature-list::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .feature-list {
    display: flex;
    column-gap: 50px;
    row-gap: 50px;
    margin-top: 50px;
    padding: 0 calc(50% - 95px);
    overflow: scroll;
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
  }

}
