:root {
  --main-color: #000;
  --main-pink: #E78FFF;
  --main-blue: #54B1FF;
  --main-purple: #300088;
  --secondary-pink: #CA288F;
  --secondary-orange: #f9a266;
  --text-color: #cfcfcf;
  --grid-columns-team: 4;
  --main-area: 50%;
  --base-header-size: 64px;
  --intro-logo-size: 124px;
  --base-text-size: 18px;
}

/* Medium Screen */
@media screen and (min-width: 600px) and (max-width: 1100px) {
  :root {
    --grid-columns-team: 2;
    --base-header-size: 48px;
    --intro-logo-size: 96px;
  }
}

/* Mobile Screen */
@media screen and (max-width: 600px) {
  :root {
    --main-area: 70%;
    --grid-columns-team: 1;
    --base-header-size: 32px;
    --intro-logo-size: 72px;
    --base-text-size: 16px;
  }
}

/* Mobile Screen */
@media screen and (max-width: 450px) {
  :root {
    --main-area: 70%;
    --intro-logo-size: 64px;
    --base-text-size: 15px;
  }
}

/* Mobile Screen */
@media screen and (max-width: 360px) {
  :root {
    --intro-logo-size: 48px;
    --base-text-size: 14px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-color);
  font-family: "Source Code Pro", sans-serif;
}

.primary-header {
  font-family: "Maven Pro", sans-serif;
  font-weight: 700;
  font-size: var(--base-header-size);
  text-align: center;
}