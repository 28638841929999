.download-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    gap: 50px;
}

.download-description {
    gap: 10px;
}

.download-header {
    font-size: 25px;
    width: 400px;
}

.download-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.download-device {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.download-qr {
    width: 200px;
}

.download-buttons div.button {
    transition: ease-in-out 0.1s;
}

.download-buttons div.button.btn-disabled {
    cursor: default;
}

.download-buttons div.button.btn-disabled .text {
    color: #D3B5DB;
}

@media screen and (max-width: 800px) {
    .download-qr {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .download-header {
        font-size: 20px;
        width: 70%;
    }
    .download-buttons {
        flex-direction: column;
        gap: 40px;
    }
}